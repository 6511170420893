import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  fonts: {
    heading: "'Max', sans-serif",
    body: "'Noto Sans', sans-serif",
  },
  colors: {
    ahuaBlue: {
      0: "#E6F2FA",
      5: "#D2E9F9",
      10: "#BCDFF8",
      20: "#82C5F4",
      30: "#55AEED",
      40: "#2F95E5",
      50: "#137ED9",
      60: "#0068CA",
      70: "#0058B7",
      80: "#0047A0",
      90: "#003686",
      100: "#00276B",
    },
    ahuaYellow: {
      0: "#FAF4DC",
      5: "#FAF1CD",
      10: "#FAEDB9",
      20: "#FAE387",
      30: "#FAD95A",
      40: "#F5CC27",
      50: "#F0C10A",
      60: "#E0B300",
      70: "#D6A700",
      80: "#C79300",
      90: "#AD7800",
      100: "#7A4D00",
    },
  },
})

export default theme
